<template>
    <div class="section">
        <div class="Projects"><h1>My projects</h1></div>
        <div class="checkr">
            <a href="https://github.com/Marleono/Checkr"><img src="../img/checkr.png" alt="website" width="491" height="240"></a>
            <div class="info">
                <h4>Checkr</h4>
                <p>I tried to recreate the part of the existing Checkr.com website. I wanted to practise doing the menu with dropdown links.</p>
            </div>
        </div>
        <div class="ice-cream">
            <div class="info">
                <h4>Ice-Cream</h4>
                <p>I used a free design model of the website on selling ice-creams and practised implementing the slider using the slider library.</p>
            </div>
            <a href="https://github.com/Marleono/ice-cream"><img src="../img/ice-cream.png" alt="website" width="491" height="240"></a>    
        </div>
    </div>
</template>

<style scoped>

    @import url('https://fonts.googleapis.com/css2?family=Biryani&display=swap');

    .section {
        margin: 0 auto;
        display: flex;
        min-height: 30rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgb(234, 244, 211), 55%, rgb(4, 139, 168, 0.5));
        font-family: 'Biryani';
    }

    .Projects {
        position: relative;
    }
    .Projects::before {
        content: '';
            background-image: url(../img/suitcase-portfolio-svgrepo-com.svg);
            position: absolute;
            background-size: cover;
            width: 47%;
            height: 87%;
            right: 11rem;
            bottom: 1rem;
    }
    .checkr,
    .ice-cream {
        display: flex;
        justify-content: space-around;
        margin: 1rem auto;
    }
    img {
        box-shadow: 1px 7px 14px rgba(242, 158, 76);
        border-radius: 40px;
    }
    h1 {
        font-family: 'Itim', cursive;
    }
    .info {
        max-width: 50%;
    }
    p {
        line-height: 2rem;
    }


    @media (max-width: 1200px) {
        .checkr,
        .ice-cream {
            display: flex;
            justify-content: space-around;
            margin: 1rem auto;
            flex-wrap: wrap;
            align-items: center;
        }
        .ice-cream {
            display: flex;
            flex-direction: column-reverse;
        }
        .info {
            max-width: 80%;
        }
        img {
            width: 588px;
            height: 320px;
        }
    }


    @media (max-width: 675px) {
        img {
            width: 471px;
            height: 244px;
        }
    }

    @media (max-width: 540px) {
        img {
            width: 356px;
            height: 197px;
        }
    }
    @media (max-width: 420px) {
        img {
            width: 280px;
            height: 146px;
        }
        .Projects::before {
        content: '';
            background-image: url(../img/suitcase-portfolio-svgrepo-com.svg);
            position: absolute;
            background-size: cover;
            width: 49%;
            height: 100%;
            right: 7rem;
            bottom: .1rem;
        }
    }
    @media (max-width: 282px) {
        img {
            width: 217px;
            height: 108px;
        }
        /* .Projects::before {
            right: 4rem;
        } */

    }

</style>