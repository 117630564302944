<template>
    <div class="section">
        <div class="vue_logo">
            <img src="../img/icons8-vue-js.svg" alt="Vue-logo">
            <h2>Made with Vue.js</h2>
        </div>
        <div class="contact_info">
            <div class="phone">
                <img src="../img/icons8-call-64.png" alt="" width="30" height="30">
                <span>+7-921-971-80-60</span>
            </div>
            <div class="github">
                <img src="../img/icons8-github-48.png" alt="" width="30" height="30">
                <span><a href="https://github.com/Marleono">https://github.com/Marleono</a></span>
            </div>
        </div>
    </div>
</template>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
    .section {
        display: flex;
        background-color: rgb(4, 139, 168, 0.3);
        flex: 0 0 auto;
        margin: 0 auto;
        justify-content: space-between;
    }
    .vue_logo {
        display: flex;
        align-items: center;
        min-width: 20%;
        gap: 1rem;
    }
    h2 {
        color: rgb(234, 244, 211);
        font-size: 1rem;
    }
    .contact_info {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: 'Itim', cursive;
        color: rgb(53, 53, 53);
    }
    .phone,
    .github {
        display: flex;
        align-items: center;
        gap: 1rem;
    }
    .github a {
        text-decoration: none;
        color: rgb(53, 53, 53);
    }
    .github a:hover {
        color: rgb(211, 179, 245);
    }
    @media (max-width: 458px) {

        .section {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }
    }

</style>