<template>
    <div class="section">
        <div class="Skills"><h1>Skills</h1></div>
        <div class="skills_info">
            <div class="photo lang">
                <div class="base front"><h4>Languages</h4></div><div class="front"><img src="../img/109528a8-ce3d-47d7-a548-98526385da4a.png" alt=""></div>
                <div class="back">
                    <span>Arabic - Intermediate</span>
                    <span>English - Advanced</span>
                    <span>Russian - Native</span>
                </div>
            </div>
            <div class="photo">
                <div class="base front"><h4>Programs</h4></div><div class="front"><img src="../img/AdobeStock_414465285.jpeg" alt=""></div>
                <div class="back">
                    <span>HTML</span>
                    <span>CSS</span>
                    <span>JavaScript</span>
                    <span>Vue (basic)</span>
                </div>
            </div>
            <div class="photo">
                <div class="base front"><h4>Soft skills</h4></div><div class="front"><img src="../img/AdobeStock_477009992.jpeg" alt=""></div>
                <div class="back">
                    <span>Time management</span>
                    <span>Interpersonal skills</span>
                    <span>Teamwork</span>
                    <span>Attention to detail</span>
                </div>
            </div>
        </div>
        <hr>
    </div>
</template>

<style scoped>
    .section {
        margin: 0 auto;
        display: flex;
        min-height: 30rem;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(rgb(4, 139, 168, 0.5), 55%, rgb(242, 158, 76, .5));
    }
    hr {
        width: 60%;
        border: 1px solid rgba(4, 139, 168, .3);
    }
    .skills_info {
        display: flex;
        justify-content: center;
        gap: 3rem;
        flex-wrap: wrap;
        padding-bottom: 4rem;
    }
    .Skills {
        position: relative;
    }
    .Skills::before {
        content: '';
            background-image: url(../img/cha-bubble-female-svgrepo-com.svg);
            position: absolute;
            background-size: cover;
            width: 82%;
            height: 82%;
            right: 6rem;
            bottom: .6rem;
    }
    .photo {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .lang::before {
        display: none;
        position: absolute;
        content: 'Tap';
            background-image: url(../img/curved-arrow-arrow-svgrepo-com.svg);
            background-size: cover;
            color: rgb(242, 158, 76);
            font-family: 'Itim', cursive;
            font-size: 1.5rem;
            width: 21%;
            height: 33%;
            right: -25rem;
            bottom: 18rem;
    }
    h1 {
        font-family: 'Itim', cursive;
    }
    img {
        position: relative;
        z-index: 1;
        width: 336px;
        height: 303px;
        border-radius: 50px;
        box-shadow: 1px 7px 14px rgba(242, 158, 76);
    }
    .base {
        position: absolute;
        z-index: 2;
        background-color: rgb(255,255,255, .6);
        width: 212px;
        height: 188px;
        backface-visibility: hidden;
        border-radius: 50px;
    }
    h4 {
        margin-top: 4rem;
        text-align: center;
        z-index: 3;
        color: rgb(53, 53, 53);
        font-size: 2rem;
    }
    .front,
    .back {
        transition: .6s linear;
    }
    .front {
        transform: perspective(600px) rotateY(0deg);
    }
    .back {
        position: absolute;
        display: flex;
        transform: perspective(600px) rotateY(180deg);
        background: linear-gradient(to bottom right,rgb(255, 255, 255), 30%, rgb(234, 244, 211));
        text-align: center;
        justify-content: center;
        flex-direction: column;
        width: 338px;
        height: 310px;
        backface-visibility: hidden;
        font-family: 'Itim', cursive;
        font-size: 2rem;
        color: rgb(53, 53, 53);
        line-height: 3rem;
        border-radius: 50px;
    }
    .photo:hover > .front {
        cursor: pointer;
        transform: perspective(600px) rotateY(-180deg);
    }
    .photo:hover > .back {
        transform: perspective(600px) rotateY(0deg);
    }

    @media (max-width: 1185px){
        .lang::before {
            display: block;
        }

    }
    @media (max-width: 800px) {
        .lang::before {
            right: -3rem;
        }

    }

    @media (max-width: 458px) {
        img {
            width: 256px;
            height: 234px;
        }
        .base {
            width: 154px;
            height: 130px;
        }
        h4 {
            margin-top: 3.2rem;
            text-align: center;
            z-index: 3;
            color: rgb(53, 53, 53);
            font-size: 1.5rem;
        }
        .back {
            width: 256px;
            height: 240px;
            font-size: 1.2rem;
        }
        .lang::before {
            bottom: 229px;
            right: -1rem;
        }
        .Skills::before {
            width: 82%;
            height: 82%;
            right: 4rem;
            bottom: .5rem;
        }
    }
    @media (max-width: 280px) {
        img {
            width: 207px;
            height: 180px;
        }
        .back {
            width: 207px;
            height: 186px;
            font-size: 1rem;
        }
    }
       
</style>