<template>
    <teleport to="body">
      <div v-if="show" @click="tryClose" class="backdrop"></div>
      <transition name="dialog">
        <dialog open v-if="show">
          <header>
            <slot name="header">
              <h2>{{ title }}</h2>
            </slot>
          </header>
          <section>
            <slot></slot>
          </section>
          <menu v-if="!fixed">
            <slot name="actions">
              <button @click="tryClose">Close</button>
            </slot>
          </menu>
        </dialog>
      </transition>
    </teleport>
  </template>
  
  <script>
  export default {
    props: {
      show: {
        type: Boolean,
        required: true,
      },
      title: {
        type: String,
        required: false,
      },
      fixed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    emits: ['close'],
    methods: {
      tryClose() {
        if (this.fixed) {
          return;
        }
        this.$emit('close');
      },
    },
  };
  </script>
  
  <style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 10;
  }
  
  dialog {
    position: fixed;
    top: 20vh;
    left: 29%;
    width: 41%;
    z-index: 100;
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: #EAF4D3;
  }
  
  header {
    background: linear-gradient(rgb(4, 139, 168), rgb(211, 179, 245));
    font-family: 'Itim', cursive;
    color: #353535;
    width: 100%;
    padding: 1rem;
  }
  
  header h2 {
    margin: 0;
  }
  
  section {
    padding: 1rem;
  }
  
  menu {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
  }
  
  .dialog-enter-from,
  .dialog-leave-to {
    opacity: 0;
    transform: scale(0.8);
  }
  
  .dialog-enter-active {
    transition: all 0.3s ease-out;
  }
  
  .dialog-leave-active {
    transition: all 0.3s ease-in;
  }
  
  .dialog-enter-to,
  .dialog-leave-from {
    opacity: 1;
    transform: scale(1);
  }
  
  @media (min-width: 768px) {
    dialog {
      left: calc(50% - 20rem);
      width: 40rem;
    }
  }
  </style>