<template>
    <div class="section">
        <div class="Education"><h1>Education</h1></div>
            <div class="course">
                <div class="dev">
                    <span>2021</span>
                </div>
                <div class="info">
                    <h4>SkillFactory</h4>
                    <p>Full stack Python web developer</p>
                    <base-button @click="showCourse = !showCourse" key="course">Details</base-button>
                    <course-info v-if="showCourse" key="course"></course-info>
                </div>
            </div>
            <div class="university_master">
                <div class="master">
                    <span>2014</span>
                </div>
                <div class="info">
                    <h4>{{ universityName }}</h4>
                    <p>{{ departmentName }}, master</p>
                    <base-button @click="showMaster = !showMaster" key="master">Details</base-button>
                    <master-info v-if="showMaster" key="master"></master-info>
                </div>
            </div>
            <div class="university_bachelor">
                <div class="bachelor">
                    <span>2012</span>
                </div>
                <div class="info">
                    <h4>{{ universityName }}</h4>
                    <p>{{ departmentName }}, bachelor</p>
                    <base-button @click="showBachelor = !showBachelor" key="bachelor">Details</base-button>
                    <bachelor-info v-if="showBachelor" key="bachelor"></bachelor-info>
                </div>
            </div>
            <hr>
    </div>
</template>

<script>
import CourseInfo from './EducationInfo/CourseInfo.vue';
import MasterInfo from './EducationInfo/MasterInfo.vue';
import BachelorInfo from './EducationInfo/BachelorInfo.vue';
import BaseButton from './BaseButton.vue';
export default {
    components: {
        CourseInfo,
        MasterInfo,
        BachelorInfo,
        BaseButton
    },
  data() {
    return {
      universityName: 'St. Petersburg State University, Saint Petersburg',
      departmentName:'Asian and African studies',
      showCourse: false,
      showMaster: false,
      showBachelor: false
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Biryani&display=swap');

    .section {
        margin: 0 auto;
        display: flex;
        min-height: 30rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgb(242, 158, 76, .5), 55%, rgb(211, 179, 245, .5));
        font-family: 'Biryani';
    }
    hr {
        width: 60%;
        border: 1px solid rgba(4, 139, 168, .3);
    }

    .Education {
        position: relative;
        
    }
    .Education::before {
        content: '';
            background-image: url(../img/education-learning-quill-school-study-writing-svgrepo-com.svg);
            position: absolute;
            background-size: cover;
            width: 30%;
            height: 60%;
            right: 10rem;
            bottom: 1rem;
    }
    .university_master,
    .university_bachelor,
    .course {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 70%;
    }
    .university_bachelor {
        padding-bottom: 3rem;
    }
    h1 {
        font-family: 'Itim', cursive;
    }
    span {
        font-family: 'Itim', cursive;
        color: rgb(4, 139, 168, 0.5);
        font-size: 2.5rem;
    }
    .info {
        color: rgb(53, 53, 53);
        min-width: 370px;
    }
 
    @media (max-width: 851px) {
        .university_master,
        .university_bachelor,
        .course {
            flex-direction: column;
            margin-bottom: 2rem;
        }
        .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }
    }


    @media (max-width: 415px) {
        span{
            font-size: 2rem;
        }
        h4,
        p {
            font-size: .7rem;
        }
        .university_master,
        .university_bachelor,
        .course {
            gap: 1rem;
            flex-direction: column;
        }
        .Education::before {
            right: 7rem;
        }
        .info {
            min-width: 100%;
        }
    }


</style>