<template>
    <div class="section">
        <div class="Contacts"><h1>Contact me</h1></div>
        <contact-form></contact-form>
    </div>
</template>

<script>
import ContactForm from './ContactForm.vue';
export default {
    components: {
        ContactForm
    }
}
</script>

<style scoped>
    .section {
        margin: 0 auto;
        display: flex;
        min-height: 30rem;
        max-width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url(../img/scattered-forcefields.svg);
        background-size: cover;
        background-repeat: no-repeat;
    }


</style>