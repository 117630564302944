<template>
    <div class="teacher">
        <p>Teach English language to adults in one-to-one lessons.</p>
    </div>
</template>


<style scoped>
    .teacher {
        display: flex;
        margin: 1rem auto;
        justify-content: space-around;
    }
    p {
        padding-top: .5rem;
        max-width: 370px;
        border-top: .5px solid rgb(4, 139, 168);
    }

    
    @media (max-width: 815px) {
        p {
            font-size: .8rem;
            max-width: 100%;
            padding: 0 .5rem;
        }
        .info {
            margin-right: 0;
        }
    }
</style>