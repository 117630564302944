<template>
<div class="section">
        <div class="Experience"><h1>Work experience</h1></div>
        <div class="teacher">
            <div class="teach">
                <span>May 2020 - currently</span>
            </div>
            <div class="info">
                <h4>Freelance</h4>
                <p>English teacher</p>
                <base-button @click="showTeacher = !showTeacher" key="teacher">Details</base-button>
                <english-teacher v-if="showTeacher " key="teacher"></english-teacher>
            </div>
        </div>
        <div class="tourism">
            <div class="guide">
                <span>May 2017 - October 2019</span>
            </div>
            <div class="info">
                <h4>Arktur travel</h4>
                <p>Tour guide</p>
                <base-button @click="showGuide = !showGuide" key="guide">Details</base-button>
                <tour-guide v-if="showGuide" key="guide"></tour-guide>
            </div>
        </div>
    </div>
</template>

<script>
import EnglishTeacher from './WorkInfo/EnglishTeacher.vue'
import TourGuide from './WorkInfo/TourGuide.vue'
import BaseButton from './BaseButton.vue'
export default {
    components: {
        EnglishTeacher,
        TourGuide,
        BaseButton
    },
  data() {
    return {
      showTeacher: false,
      showGuide: false
    }
  }
}
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Biryani&display=swap');

    .section {
        position: relative;
        margin: 0 auto;
        display: flex;
        min-height: 25rem;
        flex-direction: column;
        align-items: center;
        background: linear-gradient(rgb(211, 179, 245, .5), 55%, rgb(234, 244, 211));
        font-family: 'Biryani';
    }
    
    .Experience {
        position: relative;
    }
    .Experience::before {
        content: '';
            background-image: url(../img/bookshelf-bookcase-svgrepo-com.svg);
            position: absolute;
            background-size: cover;
            width: 30%;
            height: 90%;
            right: 15rem;
            bottom: .2rem;
    }
    h1 {
        font-family: 'Itim', cursive;
    }
    span {
        font-family: 'Itim', cursive;
        color: rgb(4, 139, 168, 0.5);
        font-size: 2rem;
    }
    .teacher,
    .tourism {
        min-width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .teacher .info,
    .tourism .info {
        margin-right: 1rem;
        padding-left: 12rem;
    }
    .tourism .info {
        padding-left: 9rem;
    }

    .info {
        color: rgb(53, 53, 53);
        min-width: 370px;
    }


    @media (max-width: 851px) {
        .teacher,
        .tourism {
            flex-direction: column;
            margin-bottom: 2rem;
        }
        .teacher .info,
        .tourism .info {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-left: 0;
            margin-right: 0;
        }
        .teach,
        .guide {
            display: flex;
            justify-content: center;
        } 

    }



    @media (max-width: 415px) {
        span{
            font-size: 1.5rem;
        }
        h4,
        p {
            font-size: .7rem;
        }
        .teacher,
        .tourism {
            gap: .3rem;
        }
        .Experience::before {
            right: 11rem;
        }
        .info {
            min-width: 100%;
        }

    }
</style>