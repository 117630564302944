<template>
    <div class="guide">
        <p>Worked with senior people, accompanied them during the tours, led excursions, did translations. Have nice experience of coping with stressful situations (medical emergencies, tourists getting lost)</p>
    </div>
</template>


<style scoped>
    .guide {
        display: flex;
        margin: 1rem auto;
        justify-content: space-around;
    }
    p {
        padding-top: .5rem;
        max-width: 370px;
        border-top: .5px solid rgb(4, 139, 168);
        text-align: justify;
    }

    
    @media (max-width: 815px) {
        p {
            font-size: .8rem;
            max-width: 100%;
            padding: 0 .5rem;
        }
    }

</style>