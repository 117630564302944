import { createApp } from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import MyHobby from './components/MyHobby.vue'
import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
    routes: [
        { path: '/hobby', component: MyHobby }
    ],
    history: createWebHashHistory(),
    scrollBehavior() {
        // always scroll 10px above the element #main
        // return {
        //   // could also be
        //   // el: document.getElementById('main'),
        //   el: '#hobby',
        //   behavior: 'smooth',
        // }
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({ 
              el: '#hobby',
              behavior: 'smooth',
             })
          }, 1000)
        })
      },
})


const app = createApp(App)
app.use(VueSmoothScroll)
app.use(router)
app.mount('#app')

