<template>
    <div class="header">
        <div class="logo">Maria Leonova</div>
        <div class="info">
            <ul>
                <li><router-link class="secret" to="/hobby">Hobby</router-link></li>
                <li><a href="#prj" v-smooth-scroll>Projects</a></li>
                <li><a href="#skills" v-smooth-scroll>Skills</a></li>
                <li><a href="#edc" v-smooth-scroll>Education</a></li>
                <li><a href="#exp" v-smooth-scroll>Experience</a></li>
                <li><a href="#cont" v-smooth-scroll>Contacts</a></li>
            </ul>
                <transition name="fade" mode="out-in">
                        <div class="burger" v-if="!show" @click="show = !show" key="menu"><img src="../img/icons8-menu.svg" alt=""></div>
                        <div class="burger close" v-else @click="show = !show" key="clear"><img src="../img/icons8-multiply-16.png" alt=""></div>
                </transition>
                <transition name="fade">
                        <ul class="mobile_menu" v-if="show">
                            <a v-for="item in items" :key="item" :href="item.href" v-smooth-scroll>{{ item.title }}</a>
                        </ul>
                </transition>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            items: [ 
                {id:1, title:"Projects", href: '#prj'},
                {id:2, title:"Skills", href: '#skills'},
                {id:3, title:"Education", href: '#edc'},
                {id:4, title:"Experience", href: '#exp'},
                {id:5, title:"Contacts", href: '#cont'}
            ],
            show: false,
        }
    }
}
</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Itim&display=swap');
    body {
        margin: 0 auto;
    }
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        padding: 0 2rem;
        border-bottom: .5px solid rgb(4, 139, 168);
        max-width: 1500px;
    }
    ul {
        display: flex;
        list-style: none;
    }
    li {
        font-family: 'Itim', cursive;
        display: inline-block;
        font-size: 1.5rem;
    }
    li:not(:last-child) {
        margin-right: 1rem;
    }
    a {
        text-decoration: none;
        color: rgb(4, 139, 168);
    }
    a:hover {
        color: rgb(211, 179, 245);
    }
    .secret {
        color: transparent;
    }
    .logo {
        font-family: 'Itim', cursive;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        background-image: linear-gradient(rgb(4, 139, 168), rgb(211, 179, 245));
        /* color: rgb(4, 139, 168); */
    }
    .logo {
        font-size: 3rem;
    }
    .burger {
        display: none;
        cursor: pointer;
    }
    .close img{
        cursor: pointer;
        width: 23px;
        height: 23px;
    }
    .info {
        position: relative;
    }
    .mobile_menu {
        display: flex;
        position: absolute;
        border-radius: 7px;
        top: 27px;
        right: -13px;
        justify-content: center;
        flex-direction: column;
        z-index: 5;
        background-color: rgba(234, 244, 211, .7);
    }
    .mobile_menu a{
        font-family: 'Itim', cursive;
        align-items: center;
        color: rgb(4, 139, 168);
        display: flex;
        line-height: 4rem;
        font-size: 2rem;
    }
    .mobile_menu a:hover {
        color: rgb(211, 179, 245);
        cursor: pointer;
    }
    @media (max-width: 848px) {
        ul {
            display: none;
        }
        .burger {
            display: block;
        }
    }
    @media (max-width: 415px) {
        .mobile_menu {
            background-color: rgb(234, 244, 211, .7);
        }
    }
</style>